import { Injectable } from '@angular/core';
import {RestService} from "./rest.service";
import {Observable} from "rxjs";
import {UserMeOutput} from "../models/UserMeOutput";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private rest: RestService) { }
  getMyProfile(): Observable<UserMeOutput> {
    return this.rest.get$<UserMeOutput>('me',true, 'user');
  }





}
