import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { authActions } from './store/auth/auth.actions';
import { generalActions } from './store/general/general.actions';
import { IdamService } from './services/idam.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(private store: Store, private idam: IdamService) {
    store.dispatch(authActions.checkingTokens());

    const hostNameSplitted = window.location.hostname.split('.');
    let clientCode = hostNameSplitted[0];
    if (clientCode === 'news'){
      clientCode = 'movesion';
    }
    this.store.dispatch(generalActions.loadClientInfo({ code: clientCode }));
  }
}
