import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectStatus } from '../store/auth/auth.reducer';

@Injectable({ providedIn: 'root' })
export class IsUserAuthorized {
  constructor(private store: Store, private router: Router) {}

  canActivateChild(
    // eslint-disable-next-line no-unused-vars
    _route: ActivatedRouteSnapshot,
    // eslint-disable-next-line no-unused-vars
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(selectStatus),
      map((result) => {
        if (result !== 'AUTHENTICATED' && result !== 'CHECKING') {
          void this.router.navigateByUrl('auth/login');
        }
        return result === 'AUTHENTICATED';
      })
    );
  }

  canActivate(
    // eslint-disable-next-line no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line no-unused-vars
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(selectStatus),
      map((result) => {
        if (
          result === 'AUTHENTICATED' ||
          result === 'CHECKING' ||
          result === 'INITIAL'
        ) {
          return true;
        }

        if (result === 'UNAUTHENTICATED') {
          void this.router.navigateByUrl('auth/login');
          return false;
        }

        return true; //status === 'AUTHENTICATED';
      })
    );
  }
}
