// import {createAction, props} from '@ngrx/store';
// import {RefreshTokenResponse} from "../../models/refreshtoken.model";
//

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const authActions = createActionGroup({
  source: 'AUTH',
  events: {
    'Logging out': emptyProps(),
    Logout: emptyProps(),
    'Logout failed': emptyProps(),
    'Checking tokens': emptyProps(),
    'Authenticating Start': emptyProps(),
    'Authenticating Success': props<{
      token: string;
      refreshToken: string;
      forceRedirect: boolean;
      redirect_url?: string;
    }>(),
    'Refresh Token End': props<{ token: string; refreshToken: string }>(),
    'Check tokens failed': emptyProps(),

    'Creating new token': props<{
      token: string;
      redirect_url: string | undefined;
    }>(),

    // export const createNewToken = createAction('[AUTH] Create new token', props<{token: string}>());
    // export const authenticatingCheck = createAction();
    // export const authenticatingStart = createAction('[AUTH] Authenticating');
    // export const authenticatingEnd = createAction();
    //
    // export const authenticatingCheckFailed = createAction();
    //
    //
    //
    //
    // export const refreshingToken = createAction('[AUTH] Refreshing token', props<{token: string; refreshToken: string}>());
    // export const refreshingTokenSuccess = createAction('[AUTH] Refreshing token success', props<RefreshTokenResponse>());
    // export const refreshingTokenFail = createAction('[AUTH] Refreshing token failed');
    //
    //
  },
});

export const redirectActions = createActionGroup({
  source: 'Redirect',
  events: {
    'Check Token for redirect': props<{ redirectUrl: string }>(),
    'Check Token for redirect Success': props<{
      token: string;
      refreshToken: string;
    }>(),
    'Check Token for redirect Failed': emptyProps(),
  },
});
