import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { TopicBaseDto, TopicWithCounters } from 'src/app/models/topic.model';
import { newsActions, settingsActions } from './news.action';
import { UserConfigurationBaseDto } from 'src/app/models/userConfig.model';
import { NewsBaseDto } from 'src/app/models/news.model';
import { TopicCounter } from 'src/app/models/User';

export type Status = 'Initial' | 'Loading' | 'Ready' | 'Error';

export interface NewsState {
  settingStatus: Status;
  newsStatus: Status;
  userConfig: UserConfigurationBaseDto | undefined;
  news: NewsBaseDto[];
  topics: TopicBaseDto[];
  newsCount: TopicCounter[];
  newsById: NewsBaseDto | undefined;
}

export const defaultState: NewsState = {
  newsStatus: 'Initial',
  settingStatus: 'Initial',
  userConfig: undefined,
  topics: [],
  news: [],
  newsCount: [],
  newsById: undefined,
};

export const newsFeature = createFeature({
  name: 'news',
  reducer: createReducer(
    defaultState,
    on(
      settingsActions.loadUserConfiguration,
      (state): NewsState => ({ ...state, settingStatus: 'Loading' })
    ),
    on(
      settingsActions.loadUserConfigurationSuccess,
      (state, action): NewsState => ({
        ...state,
        settingStatus: 'Ready',
        userConfig: action.userConfig,
      })
    ),
    on(
      settingsActions.loadUserConfigurationFailed,
      (state): NewsState => ({ ...state, settingStatus: 'Error' })
    ),
    on(newsActions.loadAllTopics, (state): NewsState => ({ ...state })),
    on(
      newsActions.loadAllTopicsSuccess,
      (state, action): NewsState => ({
        ...state,
        // newsStatus: 'Ready',
        topics: action.topics,
      })
    ),
    on(
      newsActions.loadAllTopicsFailed,
      (state): NewsState => ({ ...state, newsStatus: 'Error' })
    ),
    on(
      newsActions.loadNewsById,
      (state): NewsState => ({
        ...state,
        newsById: undefined,
        newsStatus: 'Loading',
      })
    ),
    on(newsActions.loadNewsByIdSuccess, (state, action): NewsState => {
      // const topics = action.news.topics;
      // let newsCount = [...state.newsCount];
      // if (action.news.isVisualized === false) {
      //   console.log(action.news);
      //   newsCount = [
      //     ...newsCount.map((c) => {
      //       if (topics.find((t) => t.name === c.name)) {
      //         let udpatedObject: TopicCounter = { ...c };
      //         Object.assign(udpatedObject, {
      //           name: c.name,
      //           count: c.count - 1,
      //         });
      //         return udpatedObject;
      //       }
      //       return { ...c };
      //     }),
      //   ];
      // }
      return {
        ...state,
        newsById: action.news,
        // newsCount: [...newsCount],
        newsStatus: 'Ready',
      };
    }),
    on(
      newsActions.loadNewsByIdFailed,
      (state): NewsState => ({ ...state, newsStatus: 'Error' })
    ),
    on(
      newsActions.loadNews,
      (state): NewsState => ({ ...state, news: [], newsStatus: 'Loading' })
    ),
    on(
      newsActions.loadNewsSuccess,
      (state, action): NewsState => ({
        ...state,
        news: action.news,
        newsStatus: 'Ready',
      })
    ),
    on(
      newsActions.loadNewsFailed,
      (state): NewsState => ({ ...state, newsStatus: 'Error' })
    ),
    on(
      settingsActions.updateCities,
      (state): NewsState => ({ ...state, settingStatus: 'Loading' })
    ),
    on(settingsActions.updateCitiesSuccess, (state, action): NewsState => {
      return {
        ...state,
        settingStatus: 'Ready',
        userConfig: {
          ...(state.userConfig ?? action.userConfig),
          preferredCities: action.userConfig.preferredCities,
        },
      };
    }),
    on(
      settingsActions.updateCitiesFailed,
      (state): NewsState => ({ ...state, settingStatus: 'Error' })
    ),
    on(
      newsActions.getNewsCount,
      (state): NewsState => ({ ...state, newsStatus: 'Loading' })
    ),
    on(
      newsActions.getNewsCountCompleted,
      (state, action): NewsState => ({
        ...state,
        newsStatus: 'Ready',
        newsCount: action.newsCount,
      })
    ),
    on(
      newsActions.getNewsCountFailed,
      (state): NewsState => ({ ...state, newsStatus: 'Error' })
    )
  ),
});
export const {
  selectNewsStatus,
  selectSettingStatus,
  selectUserConfig,
  selectTopics,
  selectNews,
  selectNewsById,
  selectNewsCount,
} = newsFeature;

export const selectUserCities = createSelector(
  selectUserConfig,
  (config) => config?.preferredCities
);

export const selectTopicWithNewsCount = createSelector(
  selectTopics,
  selectNewsCount,
  (topics, count) => {
    const topicsWiCount: TopicWithCounters[] = [];
    topics.forEach((t) => {
      count.forEach((c) => {
        if (t.name === c.name) {
          topicsWiCount.push({ ...t, count: c.count });
        }
      });
    });
    return topicsWiCount;
  }
);
