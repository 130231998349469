import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../infrastructure/constants';
import { RefreshTokenResponse } from '../models/refreshTokenResponse';
import { catchError, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class IdamService {
  constructor(private rest: RestService, private router: Router) {}

  get refreshTokenValue(): string {
    return localStorage.getItem(CONSTANTS.refreshToken) ?? '';
  }

  get tokenValue(): string {
    return localStorage.getItem(CONSTANTS.token) ?? '';
  }

  refreshToken(refreshToken: {
    token: string;
    refreshToken: string;
  }): Observable<RefreshTokenResponse | undefined> {
    return this.rest.post$<RefreshTokenResponse | undefined>(
      'refreshToken',
      refreshToken,
      true,
      'idam'
    );
  }

  createNewToken(token: string): Observable<RefreshTokenResponse | undefined> {
    return this.rest
      .post$<RefreshTokenResponse | undefined>(
        'new-token',
        { token },
        true,
        'idam'
      )
      .pipe(
        catchError((err) => {
          console.error('Errore nella creazione nuovo token', err);
          throw err;
        })
      );
  }

  isLogged(): boolean {
    const tokenValue = this.tokenValue;
    return tokenValue !== undefined && tokenValue !== null && tokenValue !== '';
  }

  logout(): Observable<boolean> {
    if (this.isLogged()) {
      return this.rest
        .post$<{ success: boolean }>('logout', undefined, true, 'idam')
        .pipe(
          tap(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/');
          }),
          map((res: any) => res.success),
          catchError(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/');
            return of(true);
          }),
          finalize(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/');
          })
        );
    } else {
      localStorage.clear();
      void this.router.navigateByUrl('/');
      return of(true);
    }
  }

  checkToken(): Observable<
    { token: string; refreshToken: string } | undefined
  > {
    if (this.tokenValue && this.tokenValue !== '') {
      const helper = new JwtHelperService();
      if (!helper.isTokenExpired(this.tokenValue)) {
        console.log(
          'refreshing token at ',
          helper.getTokenExpirationDate(this.tokenValue)?.toLocaleString()
        );
        return of({
          token: this.tokenValue,
          refreshToken: this.refreshTokenValue,
        });
      }
      console.log('is refreshing...+')
      return this.refreshToken({
        token: this.tokenValue,
        refreshToken: this.refreshTokenValue,
      });
    }
    return of(undefined);
  }

  setToken(token: string, refreshToken: string): void {
    localStorage.setItem(CONSTANTS.token, token);
    localStorage.setItem(CONSTANTS.refreshToken, refreshToken);
  }
}
