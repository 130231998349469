import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientUserOutput } from '../../models/client.model';
import { InstallationInfoModel } from '../../models/installationInfo';

export const generalActions = createActionGroup({
  source: 'General',
  events: {
    'Loading Client': emptyProps(),
    'Load Client Completed': props<{ client: ClientUserOutput | undefined }>(),
    'Load Client Failed': emptyProps(),

    // 'Load Modules': props<{clientCode: string}>(),
    // 'Load Modules Success': props<{modules: Pick<Module, '_id' | 'name' | 'code'>[]}>(),
    // 'Load Modules Failed': emptyProps(),

    'Load Client info': props<{ code: string }>(),
    'Load Client info Success': props<{ info: InstallationInfoModel }>(),
    'Load Client info Error': emptyProps(),

    'Opening module': props<{ moduleCode: string; clientCode: string }>(),
    'Opened module': props<{ token: string; refreshToken: string }>(),
    'Opening module failed': emptyProps(),
  },
});
