import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { generalActions } from './general.actions';
import { ClientService } from '../../services/client.service';
import { IdamService } from '../../services/idam.service';
import { InstallationInfoModel } from 'src/app/models/installationInfo';

@Injectable()
export class generalEffects {
  modules_url: Record<string, string> = {
    sworking: 'smartworking',
    mpooling: 'pooling',
    sharing: 'sharing',
    benefits: 'benefits',
  };

  loadingClient$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(generalActions.loadingClient),
        mergeMap(() => this.clientService.getMyClientInfo()),
        switchMap((client) => {
          if (client) {
            return [
              generalActions.loadClientCompleted({ client }),
              // generalActions.loadingModules(),
              // generalActions.loadClientInfo({code: client.code}),
            ];
          } else {
            return [generalActions.loadClientFailed()];
          }
        }),
        catchError(() => [generalActions.loadClientFailed()])
      );
    },
    { dispatch: false }
  );

  loadingInstallations$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(generalActions.loadClientInfo),
        mergeMap((action) =>
          this.clientService.loadInstallationInfo(action.code).pipe(
            catchError((err) => {
              const iCustom: InstallationInfoModel = {
                code: '',
                _id: '',
                logo: { url: '' },
                privacy_url: '',
                real_sso: false,
                sso: false,
                skin: {
                  _id: '',
                  code: 'movesion',
                  description: '',
                  is_default: true,
                },
                register_api: '',
                sso_conf: { login_url_redirect: '' },
                terms_conditions_url: '',
                my_active_modules: [],
              };
              return of(undefined);
            })
          )
        ),
        switchMap((info) => {
          if (info) {
            return [generalActions.loadClientInfoSuccess({ info })];
          } else {
            return [generalActions.loadClientInfoError()];
          }
        }),
        catchError(() => [generalActions.loadClientInfoError()])
      );
    },
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private idamService: IdamService,
    private clientService: ClientService // private _awardService: AwardService,
  ) {}
}
