import { createFeature, createReducer, on } from '@ngrx/store';
import { UserMeOutput } from 'src/app/models/UserMeOutput';
import { userActions } from './user.actions';

export interface UserState {
  isLoading: Boolean;
  userInfo: UserMeOutput | undefined;
}

export const defaultState: UserState = {
  isLoading: false,
  userInfo: undefined,
};

export const userFeature = createFeature({
  name: 'Profile',
  reducer: createReducer(
    defaultState,
    on(
      userActions.loadUserInfo,
      (state): UserState => ({ ...state, isLoading: true })
    ),
    on(
      userActions.loadUserInfoSuccess,
      (state, action): UserState => ({
        ...state,
        isLoading: false,
        userInfo: action.userInfo,
      })
    ),
    on(
      userActions.loadUserInfoFailed,
      (state): UserState => ({ ...state, isLoading: false })
    )
  ),
});

export const { selectUserInfo } = userFeature;
