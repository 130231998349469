import { Routes } from '@angular/router';
import { IsUserAuthorized } from './services/isUserAuthorized.service';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./home/home.component').then((c) => c.HomeComponent),
    children: [
      {
        path: ':topic/news',
        loadComponent: () =>
          import('./home/components/news-list/news-list.component').then(
            (c) => c.NewsListComponent
          ),
        canActivate: [IsUserAuthorized],
      },
      {
        path: ':topic/news/:news_id',
        loadComponent: () =>
          import(
            './home/components/news-list/news-container/news-container.component'
          ).then((m) => m.NewsContainerComponent),
        canActivate: [IsUserAuthorized],
      },
      // {
      //   path: '',
      //   pathMatch: 'full',
      //   redirectTo: 'home'
      // }
    ],
    pathMatch: 'prefix',
    canActivate: [IsUserAuthorized],
  },

  {
    path: 'auth/login',
    loadComponent: () =>
      import('./login/login.component').then((c) => c.LoginComponent),
    pathMatch: 'full',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
];
