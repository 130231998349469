import { environment } from '../environments/environment';

export class Url {
  public static UrlConcatenating(url1: string, ...params: string[]): string {
    let urlReturn = '';
    if (url1.endsWith('/')) {
      urlReturn = url1.substr(0, url1.length - 1);
    } else {
      urlReturn = url1;
    }

    params.forEach((p) => {
      if (p.endsWith('/')) {
        p = p.substr(0, p.length - 1);
      }
      if (p === '') {
      } else {
        if (!p.startsWith('/')) {
          urlReturn += '/' + p;
        } else {
          urlReturn += p;
        }
      }
    });

    return urlReturn;
  }

  public static BackendSharing(): string {
    return this.UrlConcatenating(environment.backend, 'sharing');
  }
  public static BackendClients(): string {
    return this.UrlConcatenating(environment.backend, 'clients');
  }
  public static BackendSworking(): string {
    return this.UrlConcatenating(environment.backend, 'gw1/sworking');
  }
  public static BackendAwards(): string {
    return this.UrlConcatenating(environment.backend, 'awards');
  }

  public static BackendPooling(): string {
    return this.UrlConcatenating(environment.backend, 'mpooling');
  }
  public static BackendSurvey(): string {
    return this.UrlConcatenating(environment.backend, 'survey');
  }
  public static BackendActivity(): string {
    return this.UrlConcatenating(environment.backend, 'activity');
  }

  public static BackendWallet(): string {
    return this.UrlConcatenating(environment.backend, 'gw1/wallet');
  }

  public static BackendBenefit(): string {
    return this.UrlConcatenating(environment.backend, 'gw1/benefits');
  }

  public static BackendUsers(): string {
    return this.UrlConcatenating(environment.backend, 'users');
  }
  public static BackendIdam(): string {
    return this.UrlConcatenating(environment.backend, 'idam');
  }
  public static BackendPolicy(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/policy');
  }
  public static BackendPaycheck(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/paycheck');
  }
  public static BackendVoucherHandler(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/voucher-handler');
  }

  public static BackEndMaas(): string {
    return this.UrlConcatenating(environment.backend, 'gw1/maas');
  }

  public static BackEndPortal(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/portal');
  }

  public static BackEndInsights(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/insights');
  }

  public static BackendNews(): string {
    return this.UrlConcatenating(environment.backend, 'gw2/news');
  }

  public static GetBackend(backendType: BackendType): string {
    switch (backendType) {
      case 'sharing':
        return Url.BackendSharing();
      case 'user':
        return Url.BackendUsers();
      case 'idam':
        return Url.BackendIdam();
      case 'client':
        return Url.BackendClients();
      case 'sworking':
        return Url.BackendSworking();
      case 'awards':
        return Url.BackendAwards();
      case 'pooling':
        return Url.BackendPooling();
      case 'survey':
        return Url.BackendSurvey();
      case 'activity':
        return Url.BackendActivity();
      case 'wallet':
        return Url.BackendWallet();
      case 'benefits':
        return Url.BackendBenefit();
      case 'policy':
        return Url.BackendPolicy();
      case 'paycheck':
        return Url.BackendPaycheck();
      case 'voucher-handler':
        return Url.BackendVoucherHandler();
      case 'maas':
        return Url.BackEndMaas();
      case 'portal':
        return Url.BackEndPortal();
      case 'insights':
        return Url.BackEndInsights();
      case 'news':
        return Url.BackendNews();
      case 'Unknown':
        return environment.backend;
      default:
        return environment.backend;
    }
  }
}

export type BackendType =
  | 'Unknown'
  | 'sharing'
  | 'user'
  | 'idam'
  | 'client'
  | 'sworking'
  | 'awards'
  | 'pooling'
  | 'survey'
  | 'activity'
  | 'wallet'
  | 'benefits'
  | 'policy'
  | 'paycheck'
  | 'voucher-handler'
  | 'maas'
  | 'portal'
  | 'insights'
  | 'news';
