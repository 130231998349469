import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
  provideTranslocoConfig,
  provideTranslocoLoader,
  provideTransloco,
} from '@ngneat/transloco';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
// import { generalEffects } from '../store/general/general.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { TokenInterceptor } from './services/token.interceptor';
import { AppErrorHandler } from './services/AppErrorHandler';
import { AuthEffects } from './store/auth/auth.effects';
import { authFeature } from './store/auth/auth.reducer';
import { generalEffects } from './store/general/general.effects';
import { generalFeature } from './store/general/general.reducer';
import { userFeature } from './store/user/user.reducer';
import { UserEffects } from './store/user/user.effect';
import { policyDocumentFeature } from './store/policy/policy.reducer';
import { PolicyDocumentsEffects } from './store/policy/policy.effects';
import { newsEffects } from './store/news/news.effects';
import { newsFeature } from './store/news/news.reducer';
import { provideToastr, ToastrModule } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['it', 'en'],
        defaultLang: 'it',
        // Remove this option if your application
        // doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      }),
      loader: TranslocoHttpLoader,
    }),
    provideStore(),
    provideState(generalFeature),
    provideState(authFeature),
    provideState(userFeature),
    provideState(policyDocumentFeature),
    provideState(newsFeature),
    provideEffects([
      generalEffects,
      AuthEffects,
      UserEffects,
      PolicyDocumentsEffects,
      newsEffects,
    ]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideToastr()
  ],

};
