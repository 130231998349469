import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable } from 'rxjs';
import { TopicBaseDto } from '../models/topic.model';
import { NewsBaseDto } from '../models/news.model';
import { UserConfigurationBaseDto } from '../models/userConfig.model';
import { TopicCounter } from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private rest: RestService) {}

  getUserConfig(): Observable<UserConfigurationBaseDto>{
    return this.rest.get$<UserConfigurationBaseDto>('user-configuration/get-configuration', true, 'news')
  }


  getAllTopics(): Observable<TopicBaseDto[]> {
    return this.rest.get$<TopicBaseDto[]>('topic/get-all-by-client-configuration', true, 'news');
  }

  getNews(topics?: string[], cities?: string[]): Observable<NewsBaseDto[]> {
    let query = '';
    if(cities && cities.length > 0)
    for(let city of cities){
      query += `&CitiesName=${city}`
    }
    return this.rest.get$<NewsBaseDto[]>(`user/get-news?topicsName=${topics}`, true, 'news');
  }

  getNewsById(news_id: string): Observable<NewsBaseDto>{
    return this.rest.get$<NewsBaseDto>(`get-by-id/${news_id}`, true, 'news');
  }

  associateCities(data: number[]): Observable<UserConfigurationBaseDto>{
    return this.rest.put$<UserConfigurationBaseDto>(`user-configuration/associate-cities`, data, 'news')
  }

  removeCities(cities: number[]): Observable<string>{
    let query = '';
    for(let id of cities){
      query += `cityIds=${id}&`
    }
    return this.rest.delete$(`user-configuration/remove-cities?${query}`, 'news')
  }

  getNewsCount(): Observable<TopicCounter[]>{
    return this.rest.get$<TopicCounter[]>('common/user/get-count-topic', true, 'news');
  }

}
