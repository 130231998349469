import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable, catchError, map } from 'rxjs';
import { AcceptedClientDocumentOutputDto } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private restService: RestService) { }


  getNeededDocuments(moduleCode: string = ''): Observable<AcceptedClientDocumentOutputDto[]> {
    return this.restService.get$<AcceptedClientDocumentOutputDto[]>(`documents/me/needed?moduleCode=${moduleCode}`, true,'policy').pipe(
      // catchError(err=> [])
    );
  }

  acceptPolicy(documentsIds: number[]): Observable<string> {
    return this.restService.post$('localized-documents/accept', documentsIds, true,'policy')
      .pipe(
        map(() => 'accepted'),
        catchError((err) => {
          throw err;
        })
      );
  }
}
