import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { IdamService } from './idam.service';
import { Router } from '@angular/router';
import { authActions } from '../store/auth/auth.actions';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor(
    private idamService: IdamService,
    private store: Store,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (
      request.urlWithParams.startsWith('/assets') ||
      request.url.endsWith('json')
    ) {
      return next.handle(request);
    }
    let authReq = request.clone({ withCredentials: true });
    const token = this.idamService.tokenValue;
    console.log('Intercepted');
    if (token != null) {
      console.log('adding token header');
      authReq = this.addTokenHeader(request, token);
    }
    return next.handle(authReq).pipe(
      catchError((error) => {
        console.error('an error intercepted at url', request.urlWithParams);
        if (
          error instanceof HttpErrorResponse &&
          this.isErrorTypeJwtExpired(error)
        ) {
          return this.handle401Error(authReq, next);
        }
        if (this.isRefreshTokenExpired(error)) {
          console.error('Refresh token expired...');
          localStorage.clear();
          void this.router.navigateByUrl('/login');
          return of(null);
        }
        return new Error(error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
    });
  }

  private isErrorTypeJwtExpired(error: HttpErrorResponse): boolean {
    return (
      error.error.message === 'Authorizer: jwt expired' && error.status === 403
    );
  }

  private isRefreshTokenExpired(error: HttpErrorResponse): boolean {
    return error.error.code === 'refresh_token_expired' && error.status === 401;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing$.value) {
      this.isRefreshing$.next(true);

      const token = this.idamService.tokenValue;
      const refreshToken = this.idamService.refreshTokenValue;
      if (token) {
        return this.idamService.refreshToken({ token, refreshToken }).pipe(
          switchMap((refreshedToken) => {
            if (refreshedToken) {
              this.idamService.setToken(
                refreshedToken.token,
                refreshedToken.refreshToken
              );
              this.store.dispatch(
                authActions.refreshTokenEnd({
                  token: refreshedToken.token,
                  refreshToken: refreshedToken.refreshToken,
                })
              );

              return next.handle(
                this.addTokenHeader(request, refreshedToken.token)
              );
            }

            throw new Error('Refresh token failed');
          }),
          catchError((err) => {
            localStorage.clear();
            void this.router.navigateByUrl('/auth/welcome');
            this.isRefreshing$.next(false);
            return throwError(() => err);
          })
        );
      }
    } else {
      return this.isRefreshing$.pipe(
        filter((result) => !result),
        switchMap((result) => {
          if (!result) {
            return next.handle(
              this.addTokenHeader(request, this.idamService.tokenValue)
            );
          } else {
            throw new Error('Token not refreshed');
          }
        })
      );
    }
  }
}
