import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { AcceptedClientDocumentOutputDto } from 'src/app/models/document.model';
import { policyDocumentsActions } from './policy.actions';

export const featureName = 'policyDocument';
export type DocumentPolicyStatus = 'Initial' | 'Loading' | 'Ready' | 'Error';

export interface PolicyDocumentsState {
  documents: AcceptedClientDocumentOutputDto[];
  status: DocumentPolicyStatus;
}

export const initialState: PolicyDocumentsState = {
  documents: [],
  status: 'Initial',
};

export const policyDocumentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(
      policyDocumentsActions.loadingDocuments,
      (state: PolicyDocumentsState): PolicyDocumentsState => ({
        ...state,
        status: 'Loading',
      })
    ),
    on(
      policyDocumentsActions.loadingDocumentsCompleted,
      (state, action): PolicyDocumentsState => ({
        ...state,
        documents: action.documents,
        status: 'Ready',
      })
    ),
    on(
      policyDocumentsActions.loadingDocumentsFailed,
      (state): PolicyDocumentsState => ({ ...state, status: 'Error' })
    ),
    on(
      policyDocumentsActions.acceptPolicyFailed,
      (state): PolicyDocumentsState => ({ ...state, status: 'Error' })
    )
  ),
});

export const { selectDocuments } = policyDocumentFeature;
