import { createFeature, createReducer, on } from '@ngrx/store';
import { authActions, redirectActions } from './auth.actions';

export const authFeatureName = 'auth';
export interface AuthState {
  status:
    | 'INITIAL'
    | 'CHECKING'
    | 'AUTHENTICATING'
    | 'AUTHENTICATED'
    | 'UNAUTHENTICATED';
  token: string | undefined;
  refreshToken: string | undefined;
  isRefreshingToken: boolean; //Refreshing
  isLogged: boolean;
}

export const initialState: AuthState = {
  status: 'INITIAL',
  token: undefined,
  refreshToken: undefined,
  isRefreshingToken: false,
  isLogged: false,
};

export const authFeature = createFeature({
  name: authFeatureName,
  reducer: createReducer(
    initialState,
    // on(authActions.createNewToken, (state): AuthState =>({...state, isRefreshingToken: true, status: 'CHECKING' })),
    on(
      authActions.checkingTokens,
      (state): AuthState => ({ ...state, status: 'CHECKING' })
    ),
    on(
      authActions.authenticatingStart,
      (state): AuthState => ({ ...state, status: 'AUTHENTICATING' })
    ),
    on(
      authActions.authenticatingSuccess,
      (state, action): AuthState => ({
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        status: 'AUTHENTICATED',
      })
    ),
    on(
      authActions.refreshTokenEnd,
      (state, action): AuthState => ({
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
      })
    ),
    on(
      authActions.checkTokensFailed,
      (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
    ),
    on(authActions.loggingOut, (state): AuthState => ({ ...state })),
    on(
      authActions.logout,
      (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
    ),
    on(
      authActions.logoutFailed,
      (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
    ),

    on(
      redirectActions.checkTokenForRedirect,
      (state): AuthState => ({ ...state })
    ),
    on(
      redirectActions.checkTokenForRedirectSuccess,
      (state, action): AuthState => ({
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
      })
    ),
    on(
      redirectActions.checkTokenForRedirectFailed,
      (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
    )
  ),
});

export const { selectIsLogged, selectStatus } = authFeature;
