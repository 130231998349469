import { Injectable } from '@angular/core';
import { catchError, first, Observable } from 'rxjs';
import { ClientUserOutput } from '../models/client.model';
import { RestService } from './rest.service';
import { InstallationInfoModel } from '../models/installationInfo';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private rest: RestService) {}

  getMyClientInfo(): Observable<ClientUserOutput> {
    return this.rest
      .get$<ClientUserOutput>(`user/my`, true, 'client')
      .pipe(first());
  }

  loadInstallationInfo(code: string): Observable<InstallationInfoModel> {
    if (code === 'localhost' || code === 'sharing') {
      code = 'movesion';
    }
    return this.rest
      .get$<InstallationInfoModel>(`public/byCode/${code}`, true, 'client')
      .pipe(
        // tap((res) => console.log(res))
        catchError((err) => {
          //this.notificationService.showError('Sembrano esserci problemi nella connessione Internet.');
          throw err;
        })
      );
  }
}
