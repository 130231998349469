import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserMeOutput } from 'src/app/models/UserMeOutput';

export const userActions = createActionGroup({
  source: 'user',
  events: {
    'Load user info': emptyProps(),
    'Load user info Success': props<{ userInfo: UserMeOutput }>(),
    'Load user info failed': emptyProps(),
  },
});
