import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileService } from 'src/app/services/profile.service';
import { userActions } from './user.actions';
import { catchError, mergeMap, of, switchMap } from 'rxjs';

@Injectable()
export class UserEffects {
  loadUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loadUserInfo),
      mergeMap(() => this.userService.getMyProfile()),
      switchMap((userInfo) =>
        of(userActions.loadUserInfoSuccess({ userInfo }))
      ),
      catchError(() => of(userActions.loadUserInfoFailed()))
    );
  });

  constructor(private userService: ProfileService, private actions$: Actions) {}
}
