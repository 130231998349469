import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NewsService } from 'src/app/services/news.service';
import { newsActions, settingsActions } from './news.action';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { createFeature } from '@ngrx/store';

@Injectable()
export class newsEffects {
  loadingUserConfig$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingsActions.loadUserConfiguration),
        mergeMap(() => this.newsService.getUserConfig()),
        switchMap((userConfig) =>
          of(settingsActions.loadUserConfigurationSuccess({ userConfig }))
        ),
        catchError(() => of(settingsActions.loadUserConfigurationFailed()))
      );
    },
    { dispatch: true }
  );

  loadingTopics$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(newsActions.loadAllTopics),
        mergeMap(() => this.newsService.getAllTopics()),
        switchMap((topics) => of(newsActions.loadAllTopicsSuccess({ topics }))),
        catchError(() => of(newsActions.loadAllTopicsFailed()))
      );
    },
    { dispatch: true }
  );

  loadingNews$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(newsActions.loadNews),
        mergeMap((action) =>
          this.newsService.getNews(action.topics, action.cities)
        ),
        switchMap((news) => of(newsActions.loadNewsSuccess({ news }))),
        catchError(() => of(newsActions.loadNewsFailed()))
      );
    },
    { dispatch: true }
  );

  loadingNewsById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(newsActions.loadNewsById),
        mergeMap((action) =>
          this.newsService
            .getNewsById(action.news_Id)
            .pipe(catchError(() => of(undefined)))
        ),
        switchMap((news) => {
          if (news) {
            return of(newsActions.loadNewsByIdSuccess({ news }));
          }
          return of(newsActions.loadNewsByIdFailed());
        }),
        catchError(() => of(newsActions.loadNewsByIdFailed()))
      );
    },
    { dispatch: true }
  );

  updatingCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(settingsActions.updateCities),
      mergeMap((action) =>
        this.newsService.associateCities(action.cities).pipe(
          catchError(() => {
            this.notificationService.showSuccess(
              'Si è verificato un errore durante il salvataggio'
            );
            return of(undefined);
          })
        )
      ),
      switchMap((settings) => {
        if (settings) {
          this.notificationService.showSuccess('Configurazione salvate');
          return of(
            settingsActions.updateCitiesSuccess({ userConfig: settings })
          );
        }
        return of(settingsActions.updateCitiesFailed());
      })
    );
  });

  loadNewsCouter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(newsActions.getNewsCount),
        mergeMap(() => this.newsService.getNewsCount()),
        switchMap((newsCount) =>
          of(newsActions.getNewsCountCompleted({ newsCount }))
        ),
        catchError(() => of(newsActions.getNewsCountFailed()))
      );
    },
    { dispatch: true }
  );
  updateCounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(newsActions.loadNewsByIdSuccess),
      switchMap(() => of(newsActions.getNewsCount()))
    );
  });

  loadNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(newsActions.loadAllTopicsSuccess),
        switchMap(() => of(newsActions.getNewsCount()))
      );
    },
    { dispatch: true }
  );

  constructor(
    private newsService: NewsService,
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
