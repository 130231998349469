import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { Router } from '@angular/router';
import { PolicyService } from '../../services/policy.service';
import { policyDocumentsActions } from './policy.actions';

@Injectable()
export class PolicyDocumentsEffects {
  
  loadingDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(policyDocumentsActions.loadingDocuments),
        mergeMap(() =>
          this.policyService.getNeededDocuments().pipe(
            map((documents) =>
              documents.sort((a, b) =>
                a.clientDocument.moduleCode == null &&
                b.clientDocument.moduleCode != null
                  ? -1
                  : 1
              )
            ),
            catchError(() => of(undefined))
          )
        ),
        concatMap((res) => {
          if (res) {
            return of(
              policyDocumentsActions.loadingDocumentsCompleted({
                documents: res,
              })
            );
          }
          return of(policyDocumentsActions.loadingDocumentsFailed());
        })
      ),
    { dispatch: true }
  );

  acceptDocuments = createEffect(
    () =>
      this.actions$.pipe(
        ofType(policyDocumentsActions.acceptPolicyDocuments),
        mergeMap((action) =>
          this.policyService
            .acceptPolicy(action.documentsIds)
            .pipe(
              catchError(() => [
                policyDocumentsActions.loadingDocumentsFailed(),
              ])
            )
        ),
        switchMap(() => this.policyService.getNeededDocuments()),
        switchMap((res) => {
          if (res) {
            return [
              policyDocumentsActions.loadingDocumentsCompleted({
                documents: res,
              }),
            ];
          } else {
            return [policyDocumentsActions.loadingDocumentsFailed()];
          }
        }),
        tap(() => this.router.navigateByUrl('home/categories/list')),
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,

    private policyService: PolicyService,
    private router: Router
  ) {}
}
