import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TopicCounter } from 'src/app/models/User';
import { NewsBaseDto } from 'src/app/models/news.model';
import { TopicBaseDto } from 'src/app/models/topic.model';
import { UserConfigurationBaseDto } from 'src/app/models/userConfig.model';

export const newsActions = createActionGroup({
  source: 'News',
  events: {
    'Load all topics': emptyProps(),
    'Load all topics success': props<{ topics: TopicBaseDto[] }>(),
    'Load all topics failed': emptyProps(),

    'Load news': props<{ topics: string[]; cities?: string[] }>(),
    'Load news Success': props<{ news: NewsBaseDto[] }>(),
    'Load news failed': emptyProps(),

    'Load news by Id': props<{ news_Id: string }>(),
    'Load news by Id Success': props<{ news: NewsBaseDto }>(),
    'Load news by Id Failed': emptyProps(),

    'Get News Count': emptyProps(),
    'Get News Count Completed': props<{ newsCount: TopicCounter[] }>(),
    'Get News Count Failed': emptyProps(),
  },
});

export const settingsActions = createActionGroup({
  source: 'Settings',
  events: {
    'Load user configuration': emptyProps(),
    'Load user configuration Success': props<{
      userConfig: UserConfigurationBaseDto;
    }>(),
    'Load user configuration Failed': emptyProps(),

    'Update cities': props<{ cities: number[] }>(),
    'Update cities Success': props<{ userConfig: UserConfigurationBaseDto }>(),
    'Update cities Failed': emptyProps(),
  },
});
