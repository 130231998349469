import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, of, catchError, mergeMap, concatMap, tap } from 'rxjs';
import { authActions, redirectActions } from './auth.actions';
import { IdamService } from '../../services/idam.service';
import { userActions } from '../user/user.actions';

@Injectable()
export class AuthEffects {
  checkToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.checkingTokens),
        switchMap(() => this.idamService.checkToken()),
        catchError((err) => {
          console.error(err);
          if (err.url.indexOf('refreshToken') > -1) {
            if (
              err.error.data.errors.some(
                (es: any) => es.indexOf('Not updated') > -1
              )
            ) {
              return of(
                authActions.authenticatingSuccess({
                  token: this.idamService.tokenValue,
                  refreshToken: this.idamService.refreshTokenValue,
                  forceRedirect: false,
                })
              );
            }
          }
          void this._router.navigate(['auth']);
          throw err;
        }),
        switchMap(
          (tokens: { token: string; refreshToken: string } | undefined) => {
            console.log('tokens', tokens);
            if (tokens && tokens.token && tokens.refreshToken) {
              return [
                authActions.authenticatingSuccess({
                  token: tokens.token,
                  refreshToken: tokens.refreshToken,
                  forceRedirect: false,
                }),
              ];
            } else {
              //void this._router.navigate(["auth"]);
              return [authActions.checkTokensFailed()];
            }
          }
        )
      ),
    { dispatch: true }
  );

  authenticatingEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authenticatingSuccess),
        tap((action) => {
          this.idamService.setToken(action.token, action.refreshToken);
          if (action.forceRedirect) {
            if (
              action.redirect_url !== undefined &&
              action.redirect_url !== ''
            ) {
              window.location.href = action.redirect_url;
            } else {
              void this._router.navigate(['home']);
            }
          }
        }),
        switchMap(() => [userActions.loadUserInfo()])
      ),
    { dispatch: true }
  );

  createNewToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.creatingNewToken),
      switchMap((action) =>
        this.idamService.createNewToken(action.token).pipe(
          switchMap((newToken) => {
            if (newToken) {
              return of(
                authActions.authenticatingSuccess({
                  token: newToken.token,
                  refreshToken: newToken.refreshToken,
                  forceRedirect: true,
                  redirect_url: action.redirect_url,
                })
              );
              // profileActions.loadingProfile(),
            } else {
              return of(authActions.checkTokensFailed());
            }
          })
        )
      ),
      catchError(() => of(authActions.checkTokensFailed()))
    );
  });

  loggingOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loggingOut),
        mergeMap(() => this.idamService.logout()),
        concatMap(() => {
          void this._router.navigate(['auth']);
          return of(authActions.logout());
        }),
        catchError(() => {
          void this._router.navigate(['auth']);
          return of(authActions.logoutFailed());
        })
      ),
    { dispatch: true }
  );

  checkTokenForRedirect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(redirectActions.checkTokenForRedirect),
      mergeMap((action) =>
        this.idamService.checkToken().pipe(
          switchMap((newToken) => {
            if (newToken) {
              const { token, refreshToken } = newToken;
              const url = `${action.redirectUrl}?token=${token}&refreshToken=${refreshToken}`;
              window.location.href = url;
              return of(
                redirectActions.checkTokenForRedirectSuccess({
                  token,
                  refreshToken,
                })
              );
            } else {
              return of(redirectActions.checkTokenForRedirectFailed());
            }
          })
        )
      ),
      catchError(() => of(redirectActions.checkTokenForRedirectFailed()))
    );
  });

  // refreshingToken$ = createEffect(()=>
  //   { return this.actions$.pipe(
  //     ofType(authActions.refreshingToken),
  //     switchMap((action)=> this.idamService.refreshToken({ token: action.token, refreshToken: action.refreshToken}) ),
  //     tap((response) => {
  //       if (response) {
  //         localStorage.setItem(CONSTANTS.refreshToken, response.refreshToken);
  //         localStorage.setItem(CONSTANTS.token, response.token);
  //       }
  //     }),
  //     switchMap(result => {
  //       if (result){
  //         return of(authActions.refreshingTokenSuccess({...result}));
  //       }
  //       return of(authActions.refreshingTokenFail());
  //
  //     }),
  //     catchError(() => of(authActions.refreshingTokenFail())));
  //   }
  // );

  // createNewToken$ = createEffect(()=>
  //   {
  //     return this.actions$.pipe(
  //       ofType(authActions.createNewToken),
  //       switchMap((action)=> this.idamService.createNewToken( action.token) ),
  //       tap((response) => {
  //         if (response) {
  //           localStorage.setItem(CONSTANTS.refreshToken, response.refreshToken);
  //           localStorage.setItem(CONSTANTS.token, response.token);
  //         }
  //       }),
  //       switchMap(result => {
  //         if (result) {
  //           return of(authActions.authenticatingEnd({token:result.token, refreshToken: result.refreshToken, forceRedirect: true}));
  //         } else{
  //           return of(authActions.refreshingTokenFail());
  //         }
  //       }),
  //       catchError(() => of(authActions.refreshingTokenFail())));
  //   }
  // );

  constructor(
    private actions$: Actions,
    private idamService: IdamService,
    private _router: Router
  ) {}
}
