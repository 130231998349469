import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { generalActions } from './general.actions';
import {
  ClientModuleOutputDto,
  InstallationInfoModel,
} from '../../models/installationInfo';
import { ClientUserOutput } from '../../models/client.model';


export const generalFeatureName = 'general';

export type ClientStatus = 'Initial' | 'Loading' | 'Ready' | 'Error';
export interface GeneralState {
  isLoading: boolean;
  status: ClientStatus;
  clientUserOutput: ClientUserOutput | undefined;

  //modules: Pick<Module, '_id' | 'name' | 'code'>[] | undefined;
  installationInfo: InstallationInfoModel | undefined;
}

export const initialState: GeneralState = {
  status: 'Initial',
  isLoading: false,
  clientUserOutput: undefined,

  //modules: undefined,
  installationInfo: undefined,
};

export const generalFeature = createFeature({
  name: generalFeatureName,
  reducer: createReducer(
    initialState,
    // on(generalActions.loadingStart, (state): GeneralState => ({...state, isLoading: true,})),
    // on(generalActions.loadingEnd, (state): GeneralState => ({ ...state, isLoading: false })),

    on(
      generalActions.loadingClient,
      (state): GeneralState => ({
        ...state,
        isLoading: true,
        status: 'Loading',
      })
    ),
    on(
      generalActions.loadClientCompleted,
      (state, action): GeneralState => ({
        ...state,
        isLoading: false,
        status: 'Ready',
        clientUserOutput: action.client,
      })
    ),
    on(
      generalActions.loadClientFailed,
      (state): GeneralState => ({
        ...state,
        isLoading: false,
        status: 'Error',
        clientUserOutput: undefined,
      })
    ),

    // on(generalActions.loadingModules, (state): GeneralState => ({...state, isLoading: true})),
    // on(generalActions.loadedModules, (state, action): GeneralState => ({...state, isLoading: false, modules: action.modules})),
    // on(generalActions.loadingModulesFailed, (state): GeneralState => ({...state, isLoading: false, modules: undefined})),
    //
    on(
      generalActions.loadClientInfo,
      (state): GeneralState => ({
        ...state,
        isLoading: true,
        status: 'Loading',
      })
    ),
    on(generalActions.loadClientInfoSuccess, (state, action): GeneralState => {
      console.log('state');
      return {
        ...state,
        isLoading: false,
        status: 'Ready',
        installationInfo: action.info,
      };
    }),

    // on(generalActions.loadModulesSuccess, (state, action): GeneralState => ({...state, modules: action.modules })),
    // on(generalActions.loadModules, (state, action): GeneralState => ({...state, isLoading: true })),
    // on(generalActions.loadModulesFailed, (state, action): GeneralState => ({...state, isLoading: false, modules:[], })),

    on(
      generalActions.loadClientInfoError,
      (state): GeneralState => ({
        ...state,
        // isLoading: false,
        status: 'Error',
        installationInfo: undefined,
      })
    )
  ),
  // extraSelectors: (selectInstallationInfo)=>
  //   ({selectInstallationInfo })
  extraSelectors: ({ selectInstallationInfo }) => ({
    selectSkinCode: createSelector(
      selectInstallationInfo,
      (installationInfo): string => installationInfo?.skin.code ?? 'movesion'
    ),
    selectCompanyLogoUrl: createSelector(
      selectInstallationInfo,
      (installationInfo): string | undefined =>
        installationInfo?.logo?.url ?? undefined
    ),
    selectModules: createSelector(
      selectInstallationInfo,
      (installationInfo): ClientModuleOutputDto[] =>
        installationInfo?.my_active_modules ?? []
    ),
    selectClientId: createSelector(
      selectInstallationInfo,
      (installationInfo): string | undefined =>
        installationInfo?._id ?? undefined
    ),
    selectClientCode: createSelector(
      selectInstallationInfo,
      (installationInfo): string | undefined => installationInfo?.code ?? undefined
    ),
  }),
});

export const {
  selectStatus,
  selectIsLoading,
  selectInstallationInfo,
  selectClientCode,
  selectSkinCode,
  selectCompanyLogoUrl,
  selectClientUserOutput,
  selectModules,
  selectClientId,
} = generalFeature;
