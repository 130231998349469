import { Injectable, ErrorHandler, inject, Injector, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from "../../environments/environment";

// import { environment } from '../../../environments/environment';
// import { NotificationService } from './notification.service';



/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(
    // private notificationsService: NotificationService
    @Inject(Injector) private readonly injector: Injector
  ) {
    super();
  }

  // private get notificationService(): NotificationService {
  //   return this.injector.get(NotificationService);
  // }

  override handleError(error: Error | HttpErrorResponse): void {
    // let displayMessage = 'An error occurred.';

    // if (!environment.production) {
    //   displayMessage += ' See console for details.';
    // }
    // console.error('erorr on interceptor ', displayMessage);
    if (error instanceof HttpErrorResponse){
      if (error.status === 500){
        //this.notificationService.showError(`Cè stato un problema nell'operazione che non si è conclusa, : ${error.message}`);
      }
    } else {
      if (environment.ambient === 'dev'){
        //this.notificationService.showError('An error occure, See console for details!');
      }
    }


    super.handleError(error);
  }
}
