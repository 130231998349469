import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { BackendType, Url } from '../../infrastructure/Url';
import {GenericResponse} from "../models/genericresponse";




@Injectable({
  providedIn: 'root',
})
export class RestService {
  _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }





  /**
   * Calling post action, with data. Type function was returned observable type.
   * @param url You cannot specify service if you put backend type. If in your url specify backend, backendtype was 'unknown'.
   * For example: /users/my can be changed to my and backendtype was 'users'
   * @param data
   * @param directResponse If backend response was wrapped with generic response ({success: true, data: {your data}}) if false.
   * @param backendType Specify backend to call
   */
  post$<T>(
    url: string,
    data: FormData | any | string = null,
    directResponse: boolean = false,
    backendType: BackendType = 'Unknown'
  ): Observable<T> {
    // this.prepareOptions(options);

    if (directResponse) {
      return this.http
        .post<T>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          data
          // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(finalize(() => this._isLoading$.next(false)));
    } else {
      return this.http
        .post<GenericResponse<T>>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          data
          // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((response) => {
            if (response.success) {
              return response.data;
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (response.data && (response.data as any).message) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const dataStr: string = (response.data as any)
                  .message as string;

                throw new Error(dataStr);
              }
              throw new Error(response.additionalData);
            }
          }),

          finalize(() => this._isLoading$.next(false))
        );
    }
  }

  postEmptyResponse$(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .post(Url.UrlConcatenating(Url.GetBackend(backendType), url), data, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  /**
   *
   * @param url
   * @param data
   * @param backendType
   */
  put$<T>(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<T> {
    return this.http
      .put<T>(Url.UrlConcatenating(Url.GetBackend(backendType), url), data)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  putEmptyResponse$(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .put(Url.UrlConcatenating(Url.GetBackend(backendType), url), data, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          //console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  patchEmptyResponse$(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: FormData | object | string | unknown = null,

    backendType: BackendType = 'Unknown'
  ): Observable<string> {

    return this.http
      .patch(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        data,
        // options.authorization
        //   ? { headers: options.headers, responseType: 'text' } :
        { responseType: 'text' }
      )
      .pipe(
        catchError((err) => {
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }



  get$<T>(
    url: string,
    directResponse = false,
    backendType: BackendType = 'Unknown'
  ): Observable<T> {
    if (directResponse) {
      return this.http
        .get<T>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url)
          // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          catchError((err) => {
            // this.authService.logout();
            console.error(err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
    } else {
      return this.http
        .get<GenericResponse<T>>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url)
          //options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((src) => src.data),
          catchError((err) => {
            // this.authService.logout();
            console.error(err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
    }
  }



  delete$(
    url: string,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {

    return this.http
      .delete(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        // options.authorization
        //   ? { headers: options.headers, responseType: 'text' } :
        { responseType: 'text' }
      )
      .pipe(
        // tap((res) => {
        //
        //   console.log('r:',res);
        // }),
        catchError((err) => {
          console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }
}
